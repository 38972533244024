import Model from "./base";
import firebase from "firebase";
import {ReduxStore} from './redux';


const initialState = {
  items: null,
};


export const fittedSurveysReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_FITTED_SURVEYS_SUCCESS:
      return {
        ...state,
        [action.payload.survey_category]: action.payload.survey_data
      };

    case actionTypes.GET_FITTED_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        [action.payload.survey_category]: {
          ...(state[action.payload.survey_category]),
          questions: [...action.payload.survey_questions]
        }
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_FITTED_SURVEYS_SUCCESS: 'GET_FITTED_SURVEYS_SUCCESS',
  GET_FITTED_SURVEY_QUESTIONS_SUCCESS: 'GET_FITTED_SURVEY_QUESTIONS_SUCCESS',
};


export class FittedSurveyModel extends Model {

  // static create_schema = Joi.object({
  //   first_name: Joi.string().min(1).max(50).required(),
  //   last_name: Joi.string().min(1).max(50).required(),
  //   email: Joi.string().email({tlds: false}).required(),
  // }).options({ stripUnknown: true, abortEarly: false});
  //
  // static edit_schema = Joi.object({
  //   first_name: Joi.string().min(1).max(50).required(),
  //   last_name: Joi.string().min(1).max(50).required(),
  // }).options({ stripUnknown: true, abortEarly: false});

  static get_fitted_survey_by_category_id(survey_category, fetch_questions = true) {
    firebase
      .firestore()
      .collection('surveys')
      .where('category', '==', survey_category)
      .where('enabled', '==', true)
      .where('deleted', '==', false)
      .get()
      .then((snapshots) => {
        let survey = {};
        if (snapshots.docs.length) {
          const doc = snapshots.docs[0];
          survey = {
            id: doc.id,
            ...doc.data(),
          };

          if(fetch_questions)
            FittedSurveyModel.get_fitted_survey_questions_by_survey_id(doc.id, survey_category);
        }
        ReduxStore.dispatch({
          type: actionTypes.GET_FITTED_SURVEYS_SUCCESS,
          payload: {survey_category, survey_data: survey}
        });
      });
  }

  static get_fitted_survey_questions_by_survey_id(survey_id, survey_category) {
    firebase
      .firestore()
      .collection('surveys')
      .doc(survey_id)
      .collection('questions')
      .where('enabled', '==', true)
      .where('deleted', '==', false)
      .get()
      .then((snapshots) => {
        const questions = Model.sort_by(
          snapshots.docs.map((doc) => {
            const options = Model.map_to_array(doc.data().options);
            return {
              id: doc.id,
              ...doc.data(),
              options,
            };
          })
        );
        ReduxStore.dispatch({
          type: actionTypes.GET_FITTED_SURVEY_QUESTIONS_SUCCESS,
          payload: {survey_category, survey_questions: questions}
        });
      });
  }
}
