import React, {useEffect} from 'react';
import {Switch, Redirect} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import config from "./../config";
import Role from "../models/role";
import PrivateRoute from "./private";
import HomePage from "../containers/store/home";
import StoreProfilePage from "../containers/store/store_profile";
import StoreLocationPage from '../containers/store/store_locations_page';
import StoreEmployeesPage from '../containers/store/store_employees_page';
import StoreUsersPage from '../containers/store/store_users_page';
import StoreShoesSelectionPage from "../containers/store/shoes_selection_page";
import StoreSurveysPage from "../containers/store/store_surveys_page";
import StoreSurveyPage from "../containers/store/store_survey_page";
import {StoreModel} from "../models/store";
import {ShoeModel} from "../models/shoe";
import {BrandModel} from "../models/brand";


const StoreAdminRoutes = (props) => {

  useEffect(() => {
    if(!props.shoes.items.length)
      ShoeModel.get_all_shoes();
    if(!props.brands.items.length)
      BrandModel.get_all_items();
  }, []);

  useEffect(() => {
    if (props.auth.stores) {
      const store_ids = Object.keys(props.auth.stores);
      StoreModel.get_own_stores_by_ids(store_ids);
    }
  }, [props.auth.stores]);

  useEffect(() => {
    if(!props.current_store_id)
      StoreModel.switch_own_store();
  }, [props.own_stores]);


  return (
    <Switch>

      <PrivateRoute exact path={config.routes.store.home} component={HomePage}
                    capability={Role.capabilities.read_own_reports} />

      <PrivateRoute path={config.routes.store.profile} component={StoreProfilePage}
                    capability={Role.capabilities.read_own_store_profiles} />

      <PrivateRoute path={config.routes.store.locations} component={StoreLocationPage}
                    capability={Role.capabilities.read_own_locations} />

      <PrivateRoute exact path={config.routes.store.employees} component={StoreEmployeesPage}
                    capability={Role.capabilities.read_own_employees} />

      <PrivateRoute exact path={config.routes.store.users} component={StoreUsersPage}
                    capability={Role.capabilities.read_own_users} />

      <PrivateRoute exact path={config.routes.store.surveys} component={StoreSurveysPage}
                    capability={Role.capabilities.read_own_surveys} />

      <PrivateRoute exact path={config.routes.store.survey} component={StoreSurveyPage}
                    capability={Role.capabilities.read_own_surveys} />

      <PrivateRoute path={config.routes.store.shoes} component={StoreShoesSelectionPage}
                    capability={Role.capabilities.read_own_shoes_selections} />

      <Redirect to={config.routes.store.home} />

    </Switch>
  );
};

StoreAdminRoutes.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
  own_stores: state.stores.own_stores,
  shoes: state.shoes,
  brands: state.brands,
});

export default connect(mapStateToProps)(StoreAdminRoutes);
