import firebase from "firebase";
import Joi from '@hapi/joi';
import Model from "./base";
import {ReduxStore} from "./redux";
import {baseActionTypes} from "./base";


const initialState = { };

export const storeEmployeesReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_STORE_EMPLOYEES:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_STORE_EMPLOYEES: 'GET_STORE_EMPLOYEES',
};


export class StoreEmployeesModel extends Model {

  static columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        display: false,
        filter: false,
        sort: true,
      }
    },
    {
      name: "pin",
      label: "Pin",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "last_login",
      label: "Last Login",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "updated_at",
      label: "Last Update",
      options: {
        display: false,
        filter: false,
        sort: true,
      }
    },
    {
      name: "created_at",
      label: "Created at",
      options: {
        filter: false,
        sort: true,
      }
    },
  ];

  static create_schema = Joi.object({
    first_name: Joi.string().min(2).max(50).required(),
    last_name: Joi.string().min(2).max(50).required(),
    email: Joi.string().email({tlds:false}).allow(''),
    pin: Joi.string().required(''),
    archived: Joi.bool().default(false),
  }).options({ stripUnknown: true, abortEarly: false});

  static edit_schema = Joi.object({
    first_name: Joi.string().min(2).max(50).required(),
    last_name: Joi.string().min(2).max(50).required(),
    email: Joi.string().email({tlds:false}).allow(''),
    pin: Joi.string().required(),
    archived: Joi.bool().default(false),
  }).options({ stripUnknown: true, abortEarly: false});

  static get_store_employees_by_store_id(store_id) {
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('employees')
      .where('deleted', '==', false)
      .onSnapshot((documentSnapshots) => {
        const result = documentSnapshots.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          last_login: StoreEmployeesModel.format_firestore_timestamp(doc.data().last_login),
          created_at: StoreEmployeesModel.format_firestore_timestamp(doc.data().created_at),
          updated_at: StoreEmployeesModel.format_firestore_timestamp(doc.data().updated_at),
        }));
        ReduxStore.dispatch({
          type: actionTypes.GET_STORE_EMPLOYEES,
          payload: {[store_id]: result}
        });
      });
  }

  static create_store_employee_by_store_id(store_id, employee_data) {
    return new Promise((resolve, reject) => {

      const {value, error} = StoreEmployeesModel.create_schema.validate(employee_data);

      if (error)
        return reject(StoreEmployeesModel.formatError(error));

      const employees_ref = firebase
        .firestore()
        .collection('stores')
        .doc(store_id)
        .collection('employees');

      employees_ref
        .where('pin', '==', value.pin)
        .get()
        .then((snapshots) => {
          if (snapshots.docs.length) {
            return reject({duplicate_pin: 'Duplicate pin! Please choose another one.'});
          }

          employees_ref
            .add({
              ...value,
              deleted: false,
              name: (value.first_name + ' ' + value.last_name).trim(),
              created_at: firebase.firestore.Timestamp.now(),
              updated_at: firebase.firestore.Timestamp.now(),
            })
            .then(resolve)
            .catch(error => {
              ReduxStore.dispatch({type: baseActionTypes.FIRE_STORE_ERROR, error});
            });
        })
        .catch(error => {
          ReduxStore.dispatch({ type: baseActionTypes.FIRE_STORE_ERROR, error });
        });
    });
  }

  static update_store_employee_by_employee_id(store_id, employee_id, employee_data) {
    return new Promise((resolve, reject) => {
      const {value, error} = StoreEmployeesModel.edit_schema.validate(employee_data);

      if(error)
        return reject(StoreEmployeesModel.formatError(error));

      const employees_ref = firebase
        .firestore()
        .collection('stores')
        .doc(store_id)
        .collection('employees');

      employees_ref
        .where('pin', '==', value.pin)
        .get()
        .then((snapshots) => {
          if(snapshots.docs.length) {
            return reject({duplicate_pin: 'Duplicate pin! Please choose another one.'});
          }

          employees_ref
            .doc(employee_id)
            .update({
              ...value,
              name: (value.first_name + ' ' + value.last_name).trim(),
              updated_at: firebase.firestore.Timestamp.now()
            })
            .then(resolve)
            .catch(error => {
              ReduxStore.dispatch({ type: baseActionTypes.FIRE_STORE_ERROR, error });
            });
        })
        .catch(error => {
          ReduxStore.dispatch({ type: baseActionTypes.FIRE_STORE_ERROR, error });
        });
    });
  }

  static delete_store_employee_by_employee_id(store_id, employee_id) {

    const promise = firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('employees')
      .doc(employee_id)
      .update({
        deleted: true
      });

    promise
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });

    return promise;
  }

}
