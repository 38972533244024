import React from 'react';
import ReactFC from 'react-fusioncharts';

const BrandPullEfficiencyChart = (props) => {

  if (!props.brands_data || !Object.keys(props.brands_data).length)
    return <div>No data to display.</div>;

  // Brand Pull % (brand pulls divided by total fits).
  let totalFits = 0;
  props.brands.forEach(brand => {
    totalFits += props.brands_data[brand].interactions;
  });

  const data = props.brands.map(brand => ({
    label: brand,
    value: (props.brands_data[brand].total / totalFits) * 100
  }));

  return (
    <ReactFC  type='column2d'
              dataFormat='json'
              width='100%'
              height='100%'
              dataSource={{
                data,
                chart: {
                  caption: "Brand Pull Rate",
                  subCaption: "",
                  xAxisName: "Brands",
                  yAxisName: "Pull Rate",
                  numberSuffix: "%",
                  theme: "fusion",
                  labelDisplay: "rotate",
                  slantLabel: "1"
                }
              }}
    />
  );
};

export default BrandPullEfficiencyChart;
