import React, {useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EmployeesFilter from "./employees_filter";
import LocationsFilter from "./locations_filter";
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    minWidth: 300,
    maxWidth: 500,
  },
  divider: {
    margin: '5px 0 0'
  },
  custom_autocomplete: {
    marginTop: 20
  }
}));

const FiltersPopover = ({store_id, on_filter_change}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [label, set_label] = React.useState();
  const [filters, set_filters] = React.useState({});

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handle_filter_change = useCallback((key, values) => {
    const new_filters = {
      ...filters,
      [key]: values
    };
    set_filters(new_filters);
  }, [filters]);

  useEffect(() => {
    if(!open) {
      let parts = [];
      if(filters['locations'] && filters['locations'].length) {
        parts.push(
          filters['locations'].length === 1 ? filters['locations'][0].name :
            filters['locations'].length + ' Locations'
        );
      }
      if(filters['employees'] && filters['employees'].length) {
        parts.push(
          filters['employees'].length === 1 ? filters['employees'][0].name :
            filters['employees'].length + ' Employees'
        );
      }

      const label = parts.length ? parts.join(', ') : 'No Filters';
      set_label(label);
      on_filter_change(filters);
    }
  }, [open, filters]);

  return (
    <>
      <FormControl margin='dense'>
        <InputLabel shrink>Filters</InputLabel>
        <Button variant="outlined" color="primary" size="small"
                onClick={e => setAnchorEl(e.currentTarget)}
                style={{margin: '16px 12px 0 0', minWidth: '110px'}}>
          <SearchIcon /> {label}
          <ArrowDropDownIcon />
        </Button>
      </FormControl>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.container}>
          <Typography variant='h6'>Filters</Typography>
          <Divider style={{margin: '5px 0 0'}} />
          <EmployeesFilter store_id={store_id} values={filters['employees'] || []}
                           on_change={(v) => handle_filter_change('employees', v)} />
          <LocationsFilter store_id={store_id} values={filters['locations'] || []}
                           on_change={(v) => handle_filter_change('locations', v)} />
        </div>
      </Popover>
    </>
  );
};

FiltersPopover.propTypes = {
  store_id: PropTypes.string.isRequired,
  on_filter_change: PropTypes.func.isRequired,
};

export default FiltersPopover;
