import React, {useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import {Button, MenuItem, InputLabel} from '@material-ui/core'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import FormControl from '@material-ui/core/FormControl';


const date_format = 'MM-DD-YYYY';

const date_options = {
  today: 'Today',
  yesterday: 'Yesterday',
  current_week: 'Current week (mon-sun)',
  last_week: 'Last week',
  current_month: 'Month-to-Date',
  last_month: 'Last month',
  ytd: 'YTD',
  custom: 'Custom'
};

const DatePickerDropdown = (props) => {
  const [current_date_option, set_current_date_option] = useState('today');
  const [start_date, set_start_date] = useState(moment().startOf('month').local());
  const [end_date, set_end_date] = useState(moment().local());
  const [open, set_open] = useState(false);
  const anchorRef = React.useRef(null);

  const handle_date_range_change = useCallback((event, type) => {
    handle_close(event);
    if(type && date_options[type]) {
      set_current_date_option(type);
    }
  }, []);

  const handle_close = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target))
      return;
    set_open(false);
  }, []);

  const set_dates = useCallback(() => {
    switch (current_date_option) {
      case 'today':
        props.onStartDateChange(moment().startOf('day').utc());
        props.onEndDateChange(moment.utc());
        break;
      case 'yesterday':
        props.onStartDateChange(moment().startOf('day').subtract(1, 'days').utc());
        props.onEndDateChange(moment().endOf('day').subtract(1, 'days').utc());
        break;
      case 'current_week':
        props.onStartDateChange(moment().startOf('isoWeek').utc());
        props.onEndDateChange(moment.utc());
        break;
      case 'last_week':
        props.onStartDateChange(moment().startOf('isoWeek').subtract(7, 'days').utc());
        props.onEndDateChange(moment().endOf('isoWeek').subtract(7, 'days').utc());
        break;
      case 'current_month':
        props.onStartDateChange(moment().startOf('month').utc());
        props.onEndDateChange(moment().utc());
        break;
      case 'last_month':
        props.onStartDateChange(moment().startOf('month').subtract(1,'months').utc());
        props.onEndDateChange(moment().endOf('month').subtract(1,'months').utc());
        break;
      case 'ytd':
        props.onStartDateChange(moment().startOf('year').utc());
        props.onEndDateChange(moment().utc());
        break;
      case 'custom':
        if(start_date && end_date) {
          props.onStartDateChange(start_date);
          props.onEndDateChange(end_date);
        }
        break;
    }
  }, [start_date, end_date, current_date_option]);

  useEffect(() => {
    set_dates();
  }, [current_date_option]);

  return (
    <>
      <div style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', justifyContent: 'flex-end' }}>
        <div>
          {
            current_date_option === 'custom' &&
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar disableFuture autoOk
                variant="inline"
                format={date_format}
                margin="dense"
                label="Start Date"
                maxDate={end_date}
                value={start_date}
                onChange={set_start_date}
                style={{marginRight: '15px', maxWidth: 150}}
              />
              <KeyboardDatePicker
                disableToolbar disableFuture autoOk
                variant="inline"
                format={date_format}
                margin="dense"
                label="End Date"
                minDate={start_date}
                value={end_date}
                onChange={set_end_date}
                style={{maxWidth: 150}}
              />
              <Button variant="contained" color="primary" onClick={set_dates}
                      style={{margin: '24px 10px 0px 15px', padding: '4px 0'}}>
                Run
              </Button>
            </MuiPickersUtilsProvider>
          }
        </div>
        <div>
          <FormControl margin='dense'>
            <InputLabel shrink>Date Range</InputLabel>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="Date range"
              aria-haspopup="menu"
              onClick={() => set_open(!open)}
              ref={anchorRef}
              buttonRef={anchorRef}
              style={{margin: '16px 0 0 0', minWidth: '110px'}}
            >
              { date_options[current_date_option] }
              <ArrowDropDownIcon />
            </Button>
          </FormControl>
        </div>
      </div>
      <Popper open={open} anchorEl={anchorRef.current} style={{zIndex: 9999}}
              placement='bottom-end'>
        <Paper>
          <ClickAwayListener onClickAway={handle_close}>
            <MenuList id="split-button-menu">
              {
                Object.keys(date_options).map((key, i) => (
                  <MenuItem key={i} onClick={(e)=>handle_date_range_change(e, key)}>
                    {date_options[key]}
                  </MenuItem>
                ))
              }
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

DatePickerDropdown.propTypes = {
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired
};

export default DatePickerDropdown;
