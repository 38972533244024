import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {Chip, Checkbox} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {StoreLocationsModel} from "../../../../models/store_locations";
import Model from "../../../../models/base";
import VirtualList from '../../../../components/virtual_list/virtual_list';
import PropTypes from 'prop-types';


const useStyles = makeStyles({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const LocationsFilter = ({store_id, store_locations, values, on_change}) => {
  const classes = useStyles();
  const [locations, set_locations] = useState([]);

  useEffect(() => {
    if(!store_locations[store_id])
      StoreLocationsModel.get_store_locations_by_store_id(store_id);
    else
      set_locations(Model.sort_by(store_locations[store_id], 'name'));
  }, [store_id, store_locations]);

  return (
    <Autocomplete
      disableListWrap
      classes={classes}
      style={{marginTop: 20}}
      ListboxComponent={VirtualList}
      multiple margin='dense' size="small"
      disableCloseOnSelect
      options={locations}
      value={values}
      getOptionLabel={option => option.name}
      getOptionSelected={(option) => !!values.find(v => v.id === option.id)}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Locations"
          fullWidth
        />
      )}
      renderTags={value => (
        value.length === 1 ?
          <Chip size='small' label={value[0].name} /> : <Chip size='small' label={value.length + ' Locations'} />
      )}
      onChange={(e, values) => {
        on_change(values.map(v => ({
          id: v.id,
          name: v.name
        })));
      }}
      />
  );
};

LocationsFilter.propTypes = {
  store_id: PropTypes.string.isRequired,
  on_change: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  store_locations: {
    ...state.store_locations
  },
});

export default connect(mapStateToProps)(LocationsFilter);
