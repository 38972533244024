import React from 'react';
import ReactFC from 'react-fusioncharts';


const BrandPullChart = (props) => {

  if (!props.brands_data || !Object.keys(props.brands_data).length)
    return <div>No data to display.</div>;

  const data = props.brands.map(brand => ({
    label: brand,
    value: props.brands_data[brand].total
  }));

  return (
    <ReactFC  type='column2d'
              dataFormat='json'
              width='100%'
              height='100%'
              dataSource={{
                data,
                chart: {
                  caption: "Brand Pull Rate",
                  subCaption: "",
                  xAxisName: "Brands",
                  yAxisName: "Pulls",
                  numberSuffix: "",
                  theme: "fusion",
                  labelDisplay: "rotate",
                  slantLabel: "1"
                }
              }}
    />
  );
};

export default BrandPullChart;
