import firebase from "firebase";
import Model from "./base";
import {ReduxStore} from "./redux";
import {baseActionTypes} from "./base";


const initialState = { };

export const storeShoesReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_STORE_SHOES_SELECTIONS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_STORE_SHOES_SELECTIONS: 'GET_STORE_SHOES_SELECTIONS',
};


export class StoreShoesModel extends Model {

  static get_shoes_visibility_by_store_id(store_id) {
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('shoes')
      .onSnapshot((documentSnapshots) => {
        const result = {};
        documentSnapshots.docs.forEach((doc) => {
          result[doc.id] = doc.data();
        });
        ReduxStore.dispatch({
          type: actionTypes.GET_STORE_SHOES_SELECTIONS,
          payload: {[store_id]: result}
        });
      })
  }

  static update_shoes_visibility_by_store_id(store_id, hidden_brands, hidden_shoes) {
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('shoes')
      .doc('hidden_brands')
      .set(hidden_brands)
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });

    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('shoes')
      .doc('hidden_shoes')
      .set(hidden_shoes)
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });
  }

}
