import firebase from "firebase";
import Joi from '@hapi/joi';
import Model from "./base";
import {ReduxStore} from "./redux";
import {baseActionTypes} from "./base";
// import surveys from "../containers/fitted/surveys";


const initialState = { };

export const storeSurveysReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_STORE_SURVEYS:
      return {
        ...state,
        ...action.payload
      };

    case actionTypes.GET_STORE_SURVEY_QUESTIONS:
      const new_state = {...state};
      if(new_state[action.payload.store_id] && new_state[action.payload.store_id][action.payload.survey_id])
        new_state[action.payload.store_id][action.payload.survey_id].questions = action.payload.questions;
      return new_state;

    default:
      return state;
  }
};


export const actionTypes = {
  GET_STORE_SURVEYS: 'GET_STORE_SURVEYS',
  GET_STORE_SURVEY_QUESTIONS: 'GET_STORE_SURVEY_QUESTIONS',
};

const settings = {
  MAX_ACTIVE_QUESTIONS_PER_STORE: 3
};

export class StoreSurveysModel extends Model {

  static columns = [
    {
      name: "name",
      label: "Name",
      options: {
        // display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "updated_at",
      label: "Updated At",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
  ];

  static create_survey_schema = Joi.object({
    name: Joi.string().min(1).max(50).required(),
  }).options({ stripUnknown: true, abortEarly: false});

  static edit_survey_schema = Joi.object({
    name: Joi.string().min(1).max(50).required(),
  }).options({ stripUnknown: true, abortEarly: false});

  static survey_question_schema = Joi.object({
    id: Joi.string().min(10).max(50),
    label: Joi.string().min(1).max(50).required(),
    order: Joi.number().min(0).max(1000).required(),
    type: Joi.string().min(5).max(20).required(),
    options: Joi.array(),
    enabled: Joi.bool().required(),
    deleted: Joi.bool().required(),
    updated_at: Joi.object(),
    created_at: Joi.object().required(),
  }).options({ stripUnknown: true, abortEarly: false});


  static get_store_surveys(store_id) {
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('surveys')
      .where('deleted', '==', false)
      .onSnapshot((documentSnapshots) => {
        const surveys = {};
        documentSnapshots.docs.forEach((doc) => {
          surveys[doc.id] = {
            id: doc.id,
            ...doc.data(),
            created_at: StoreSurveysModel.format_firestore_timestamp(doc.data().created_at),
            updated_at: StoreSurveysModel.format_firestore_timestamp(doc.data().updated_at),
          };
        });
        ReduxStore.dispatch({
          type: actionTypes.GET_STORE_SURVEYS,
          payload: {[store_id]: surveys}
        });
      })
  }

  static create_store_survey(store_id, survey_data) {
    const {value, error} = StoreSurveysModel.create_survey_schema.validate(survey_data);

    if(error)
      return Promise.reject(StoreSurveysModel.formatError(error));

    return firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('surveys')
      .add({
        ...value,
        deleted: false,
        created_at: firebase.firestore.Timestamp.now(),
        updated_at: firebase.firestore.Timestamp.now()
      })
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });
  }

  static update_store_survey(store_id, survey_id, survey_data) {
    const {value, error} = StoreSurveysModel.edit_survey_schema.validate(survey_data);

    if(error)
      return Promise.reject(this.formatError(error));

    return firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('surveys')
      .doc(survey_id)
      .update({
        ...value,
        updated_at: firebase.firestore.Timestamp.now()
      })
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });
  }

  static delete_store_survey(store_id, survey_id) {
    return firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('surveys')
      .doc(survey_id)
      .update({
        deleted: true
      })
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });
  }



  static get_store_survey_questions(store_id, survey_id) {
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('surveys')
      .doc(survey_id)
      .collection('questions')
      .where('deleted', '==', false)
      .onSnapshot((documentSnapshots) => {
        const questions = Model.sort_by(
          documentSnapshots.docs.map((doc) => {
            const options = Model.map_to_array(doc.data().options);

            return {
              id: doc.id,
              ...doc.data(),
              options,
              created_at: doc.data().created_at,
              updated_at: doc.data().updated_at,
            };
          })
        );

        ReduxStore.dispatch({
          type: actionTypes.GET_STORE_SURVEY_QUESTIONS,
          payload: {store_id, survey_id, questions}
        });
      })
  }

  // static create_store_survey_question(store_id, survey_id, question_data) {
  //   const {value, error} = StoreSurveysModel.create_survey_question_schema.validate(question_data);
  //
  //   if(error)
  //     return Promise.reject(StoreSurveysModel.formatError(error));
  //
  //   return firebase
  //     .firestore()
  //     .collection('stores')
  //     .doc(store_id)
  //     .collection('surveys')
  //     .doc(survey_id)
  //     .collection('questions')
  //     .add({
  //       ...value,
  //       deleted: false,
  //       created_at: firebase.firestore.Timestamp.now(),
  //       updated_at: firebase.firestore.Timestamp.now()
  //     })
  //     .catch(error => {
  //       ReduxStore.dispatch({
  //         type: baseActionTypes.FIRE_STORE_ERROR,
  //         error
  //       });
  //     });
  // }
  //
  // static update_store_survey_question(store_id, survey_id, question_id, question_data) {
  //   const {value, error} = StoreSurveysModel.edit_survey_question_schema.validate(question_data);
  //
  //   if(error)
  //     return Promise.reject(this.formatError(error));
  //
  //   return firebase
  //     .firestore()
  //     .collection('stores')
  //     .doc(store_id)
  //     .collection('surveys')
  //     .doc(survey_id)
  //     .collection('questions')
  //     .doc(question_id)
  //     .update({
  //       ...value,
  //       updated_at: firebase.firestore.Timestamp.now()
  //     })
  //     .catch(error => {
  //       ReduxStore.dispatch({
  //         type: baseActionTypes.FIRE_STORE_ERROR,
  //         error
  //       });
  //     });
  // }]

  static generate_new_survey_question(order) {
    return {
      id: StoreSurveysModel.generate_new_id(),
      label: 'New Question',
      type: 'textbox',
      order: order,
      options: [],
      enabled: true,
      deleted: false,
      created_at: StoreSurveysModel.get_current_timestamp(),
    };
  }

  static generate_new_survey_question_option(order) {
    return {
      id: StoreSurveysModel.generate_new_id(),
      label: 'New Option',
      order: order,
      enabled: true,
      deleted: false,
      created_at: StoreSurveysModel.get_current_timestamp(),
    };
  }

  static set_store_survey_questions(store_id, survey_id, questions_data) {

    const batch = firebase.firestore().batch();
    const errors = [];
    const survey_ref = firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('surveys')
      .doc(survey_id);

    const questions_ref = survey_ref
      .collection('questions');

    const question_orders = {};

    let count_active_questions = 0;

    questions_data.forEach((question) => {
      if(!question.id)
        question.id = Model.generate_new_id();

      question_orders[question.id] = question.order;

      if(question.fitted)
        return;

      if(question.enabled && !question.deleted)
        count_active_questions++;

      const {value, error} = StoreSurveysModel.survey_question_schema.validate(question);

      if(error)
        return errors.push(StoreSurveysModel.formatError(error));

      const options = Model.array_to_map(value.options);

      batch.set(questions_ref.doc(value.id), {
        ...value,
        options,
        updated_at: StoreSurveysModel.get_current_timestamp(),
      }, { merge: true });
    });

    if(errors && errors.length)
      return Promise.reject(errors);

    if(count_active_questions > settings.MAX_ACTIVE_QUESTIONS_PER_STORE)
      return Promise.reject([{max_questions: `You can have up to ${settings.MAX_ACTIVE_QUESTIONS_PER_STORE} active questions.`}]);

    batch.update(survey_ref, {question_orders});

    return batch.commit();
  }

  static delete_store_survey_question(store_id, survey_id, question_id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('stores')
        .doc(store_id)
        .collection('surveys')
        .doc(survey_id)
        .collection('questions')
        .doc(question_id)
        .update({
          deleted: true
        })
        .then(resolve)
        .catch(error => {
          // Handle the case that document does not exist
          if (error.code === "not-found") {
            resolve();
          }
          else {
            ReduxStore.dispatch({
              type: baseActionTypes.FIRE_STORE_ERROR,
              error
            });
            reject();
          }
        });
    });
  }
}
