import React, {useState} from 'react';


const ChartTabs = (props) => {

  let [index, set_index] = useState(0);

  return (
    <>
      {
        props.children.filter((child, i) => i===index)
      }
      <span style={{
        // backgroundColor: 'red',
        position: 'absolute',
        left: 20,
        bottom: 12,
        // height: '38px',
        zIndex: 20
      }}>
        {
          props.labels.map((label, i) => (
            <button key={i} onClick={()=>{set_index(i)}}
                    style={{
                      display: 'block',
                      padding: '3px 8px',
                      margin: '0 0 4px',
                      width: '100%',
                      borderRadius: 10,
                      borderColor: '#515796',
                      borderWidth: 2,
                      cursor: 'pointer',
                      backgroundColor: i===index ? '#515796' : '#fff'
                    }}>
              <span style={{
                      width: 18,
                      fontSize: 12,
                      fontWeight: '600',
                      flexDirection: 'row',
                      textAlign: 'center',
                      color: i===index ? '#fff' : '#000',
                      lineHeight: '17px'
                    }}>
                {label}
              </span>
            </button>
          ))
        }
      </span>
    </>
  );
};

ChartTabs.defaultProps = {
  is_fullscreen: false,
  toggle_fullscreen: () => {}
};

export default ChartTabs;
