import React from 'react';
import ReactFC from 'react-fusioncharts';


const BrandSalesPerformanceByAgeBarChart = (props) => {

  if (!props.brand_pulls || !Object.keys(props.brand_pulls).length)
    return <div>No data to display.</div>;

  const categories = [{ "category": props.ages.map(age => ({"label": age})) }];

  const dataset = props.brands.map(brand => ({
    seriesname: brand,
    data: props.ages.map(age => ({
      value: props.brand_pulls[brand].ages[age] ? props.brand_pulls[brand].ages[age].purchased : 0
    }))
  }));

  return (
    <ReactFC  type='stackedcolumn2d'
              dataFormat='json'
              width='100%'
              height='100%'
              dataSource={{
                chart: {
                  caption: "Brand Sales Performance - Age",
                  subCaption: "",
                  xAxisName: "Ages",
                  yAxisName: "Total sales",
                  numberSuffix: "",
                  plottooltext: "$seriesName - $percentValue", //"<strong>$dataValue</strong> - <strong>$seriesName</strong>",
                  theme: "fusion",
                  labelDisplay: "rotate",
                  slantLabel: "1",
                  showyaxisvalues: false,
                  // legendposition: "right",
                  // drawcrossline: "1"
                },
                categories,
                dataset
              }}
            />
  );
};

BrandSalesPerformanceByAgeBarChart.defaultProps = {
  brands: [],
  ages: [],
  brand_pulls: null
};

export default BrandSalesPerformanceByAgeBarChart;
