import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactFC from 'react-fusioncharts';
import {GridList, GridListTile} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    height: 'calc(100% - 35px)'
  },
}));

const BrandSalesPerformanceByMileagePieChart = (props) => {

  const [brand_mode, set_brand_mode] = useState(false);
  const classes = useStyles();

  if (!props.brands_data || !Object.keys(props.brands_data).length)
    return <div>No data to display.</div>;

  let dataset;
  if (brand_mode) {
    dataset = props.running_mileages.map(mileage => {
      let sum = 0;
      return {
        title: mileage,
        data: props.brands
          .map(brand => {
            const value = props.brands_data[brand].running_mileages[mileage] ? props.brands_data[brand].running_mileages[mileage].purchased : 0;
            sum += value;
            return {value, brand};
          })
          .map(({value, brand}) => (
            {label: brand, value: (value / sum) * 100}
          ))
      };
    });
  }
  else {
    dataset = props.brands.map(brand => {
      let sum = 0;
      return {
        title: brand,
        data: props.running_mileages
          .map(mileage => {
            const value = props.brands_data[brand].running_mileages[mileage] ? props.brands_data[brand].running_mileages[mileage].purchased : 0;
            sum += value;
            return {value, mileage};
          })
          .map(({value, mileage}) => (
            {label: mileage, value: (value / sum) * 100}
          ))
      };
    });
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          // flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          backgroundColor: 'white',
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          paddingBottom: 0,
        }} >
        <h4 style={{
          width: '100%',
          margin: '18px 0 0',
          textAlign: 'center',
          fontSize: 17,
          fontFamily: "Arial",
          fontWeight: '500',
          color: '#5b5b5b'
        }} >
          Brand Sales Performance - Mileage
        </h4>
        {/*<Grid container>*/}
        <GridList cols={props.cols} className={classes.gridList} >
        {
          dataset.map((data, i)=> (
            <GridListTile key={i} style={{height:'100%'}}>
            {/*<Grid item xs={3} key={i}>*/}
              <ReactFC key={i} type='pie2D' dataFormat='json' width='100%' height='100%'
                       dataSource={{
                         data: data.data,
                         chart: {
                            caption: data.title,
                            // subcaption: "In MMbbl = One Million barrels",
                            numberSuffix: "%",
                            plottooltext: "<strong>$dataValue</strong> sales - <strong>$label</strong>",
                            theme: "fusion",
                            labelDisplay: "rotate",
                            slantLabel: "1",
                            // showLabels: false,
                            showValues: false,
                            showlegend: false,
                            showAxis: false,
                          }
                       }} />
            {/*</Grid>*/}
            </GridListTile>
          ))
        }
        {/*</Grid>*/}
        </GridList>
      </div>
      <button onClick={()=>{set_brand_mode(!brand_mode)}}
              style={{
                position: 'absolute',
                left: 16,
                top: 16,
                flexDirection: 'row',
                flexWrap: 'wrap',
                background: 'none',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
                fontSize: '28px',
                color: '#222'
              }}>
        <FontAwesomeIcon icon={brand_mode ? faToggleOn : faToggleOff} />
      </button>
    </>
  );
};

BrandSalesPerformanceByMileagePieChart.propTypes = {
  brands: PropTypes.array.isRequired,
  brands_data: PropTypes.object.isRequired,
  running_mileages: PropTypes.array.isRequired,
  cols: PropTypes.number.isRequired
};

export default BrandSalesPerformanceByMileagePieChart;
