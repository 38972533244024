import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {TextField, Chip, ListSubheader} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {StoreEmployeesModel} from "../../../../models/store_employee";
import Model from "../../../../models/base";
import VirtualList from '../../../../components/virtual_list/virtual_list';


const useStyles = makeStyles({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});


const EmployeesFilter = ({store_id, store_employees, values, on_change}) => {
  const classes = useStyles();
  const [employees, set_employees] = useState([]);

  useEffect(() => {
    if(!store_employees[store_id])
      StoreEmployeesModel.get_store_employees_by_store_id(store_id);
    else
      set_employees(Model.sort_by(store_employees[store_id], 'name'));
  }, [store_id, store_employees]);

  return (
    <Autocomplete
      disableListWrap
      classes={classes}
      style={{marginTop: 20}}
      ListboxComponent={VirtualList}
      renderGroup={params => [
        <ListSubheader key={params.key} component="div">
          {params.key}
        </ListSubheader>, params.children,
      ]}
      groupBy={option => option.name ? option.name[0].toUpperCase() : ''}
      multiple margin='dense' size="small"
      disableCloseOnSelect
      options={employees}
      value={values}
      getOptionLabel={option => option.name}
      getOptionSelected={(option) => !!values.find(v => v.id === option.id)}
      renderOption={(option, state) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{marginRight: 8}}
            checked={state.selected}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label="Employees"
            fullWidth
          />
        );
      }}
      renderTags={value => (
        value.length === 1 ?
          <Chip size='small' label={value[0].name} /> : <Chip size='small' label={value.length + ' Employees'} />
      )}
      onChange={(e, values) => {
        on_change(values.map(v => ({
          id: v.id,
          name: v.name
        })));
      }}
      />
  );
};

EmployeesFilter.propTypes = {
  store_id: PropTypes.string.isRequired,
};


const mapStateToProps = state => ({
  store_employees: {
    ...state.store_employees
  },
});

export default connect(mapStateToProps)(EmployeesFilter);
