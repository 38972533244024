import React from 'react';
import ReactFC from 'react-fusioncharts';
import PropTypes from 'prop-types';


const BrandPullByEmployeeChart = (props) => {
  if (!props.employees_data || !Object.keys(props.employees_data).length)
    return <div>No data to display.</div>;

  const categories = [{"category": props.employees.map(employee => ({"label": employee}))}];

  const dataset = props.brands.map(brand => ({
    seriesname: brand,
    data: props.employees.map(employee => ({
      value: props.employees_data[employee].brands_pulled[brand] ? props.employees_data[employee].brands_pulled[brand].pulled_total : 0
    }))
  }));

  console.log(dataset);

  return (
    <ReactFC type='stackedcolumn2d'
             dataFormat='json'
             width='100%'
             height='100%'
             dataSource={{
               chart: {
                 caption: "Brands Pulled by Team Member",
                 subCaption: "",
                 xAxisName: "Team Members",
                 yAxisName: "Shoe Pulls",
                 numberSuffix: "",
                 plottooltext: "$label pulled <strong>$dataValue</strong> shoes of <strong>$seriesName</strong> - <strong>$percentValue</strong>",
                 theme: "fusion",
                 labelDisplay: "rotate",
                 slantLabel: "1",
                 // legendposition: "right",
                 // drawcrossline: "1"
               },
               categories,
               dataset
             }}
    />
  );
};

BrandPullByEmployeeChart.propTypes = {
  employees_data: PropTypes.object.isRequired
};

export default BrandPullByEmployeeChart;

