import {ReduxStore} from "./redux";
import {actionTypes as storeActionTypes} from "./store";

export const RoleTypes = {
  fitted: "fitted",
  stores: "stores",
};

const initialState = {
  // settings: null,
  current_role_type: null
};

export const actionTypes = {
  // GET_USER_ROLE: 'GET_USER_ROLE',
  // GET_USER_CAPABILITIES: 'GET_USER_CAPABILITIES',
  // RESET_USER_ROLES: 'RESET_USER_ROLES',
  SWITCH_ROLE: 'SWITCH_ROLE',
};

export const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    // case actionTypes.GET_USER_CAPABILITIES:
    //   return {
    //     ...state,
    //     settings: action.payload
    //   };
    case actionTypes.SWITCH_ROLE:
      return {
        ...state,
        current_role_type: action.payload
      };
    // case actionTypes.RESET_USER_ROLES:
    //   return {
    //     ...state,
    //     current_role_type: null
    //   };
    default:
      return state;
  }
};


export default class Role {

  // static get_role_capabilities() {
  //   firebase
  //     .firestore()
  //     .collection('roles')
  //     .onSnapshot((querySnapshot) => {
  //       const roles = [];
  //       querySnapshot.forEach((doc) => {
  //         roles[doc.id] = doc.data();
  //       });
  //       console.log("Role capabilities are updated", roles);
  //       ReduxStore.dispatch({ type: actionTypes.GET_USER_CAPABILITIES, payload: roles });
  //     });
  // }

  // static getRole() {
  //   return ReduxStore.getState().role;
  // }

  static switch_role_type(target_role_type) {
    const auth = ReduxStore.getState().auth;

    // Check target ui type is valid
    if(target_role_type && !auth[target_role_type])
      return console.error(`User does not have access to ${target_role_type} UI!`);

    let payload = '';

    if(target_role_type) {
      payload = target_role_type;
      ReduxStore.dispatch({type: storeActionTypes.RESET_STORES});
    }
    else {
      // Target type is not valid or not provided so set a default user type
      // If user is on a route that belongs to fitted/stores then check if user has that access
      if (auth[RoleTypes.fitted] && window.location.pathname.indexOf('/fitted/') === 0)
        payload = RoleTypes.fitted;
      else if (auth[RoleTypes.stores] && window.location.pathname.indexOf('/store/') === 0)
        payload = RoleTypes.stores;
      else if (auth[RoleTypes.fitted])
        payload = RoleTypes.fitted;
      else if (auth[RoleTypes.stores])
        payload = RoleTypes.stores;
    }

    if(payload)
      ReduxStore.dispatch({ type: actionTypes.SWITCH_ROLE, payload });
  }

  // static resetRole() {
  //   ReduxStore.dispatch({type: actionTypes.RESET_USER_ROLES});
  // }

  static user_has_capability(capability) {
    // const role = Role.getRole();
    // return role &&
      // role.current_role &&
      // role.settings &&
      // role.settings[role.current_role] &&
      // role.settings[role.current_role].capabilities &&

    const {stores, auth, role} = ReduxStore.getState();

    if(role.current_role_type === RoleTypes.fitted) {
      return (
        auth &&
        auth.fitted &&
        auth.fitted.capabilities &&
        auth.fitted.capabilities[capability] === true
      );
    }
    else if(role.current_role_type === RoleTypes.stores) {
      return (
        stores &&
        stores.current_store_id &&
        auth &&
        auth.stores &&
        auth.stores[stores.current_store_id] &&
        auth.stores[stores.current_store_id].capabilities &&
        auth.stores[stores.current_store_id].capabilities[capability] === true
      );
    }
    else
      return false;
  }

  static fitted_user_capabilities = [
    {
      id: 'read_all_reports',
      label: 'View Dashboard'
    },
    {
      id: 'read_all_stores',
      label: 'View Stores',
      requirements: [],
      children: [
        {
          id: 'read_all_store_profiles',
          label: 'View Store Profiles',
          requirements: ['read_all_stores'],
          children: [
            {
              id: 'create_all_store_profiles',
              label: 'Create Store Profiles',
              requirements: ['read_all_stores', 'read_all_store_profiles']
            },
            {
              id: 'update_all_store_profiles',
              label: 'Update Store Profiles',
              requirements: ['read_all_stores', 'read_all_store_profiles']
            },
          ]
        },
        {
          id: 'read_all_locations',
          label: 'View Store Locations',
          requirements: ['read_all_stores'],
          children: [
            {
              id: 'create_all_locations',
              label: 'Create Store Locations',
              requirements: ['read_all_stores', 'read_all_locations']
            },
            {
              id: 'update_all_locations',
              label: 'Update Store Locations',
              requirements: ['read_all_stores', 'read_all_locations']
            },
            {
              id: 'delete_all_locations',
              label: 'Delete Store Locations',
              requirements: ['read_all_stores', 'read_all_locations']
            },
          ]
        },
        {
          id: 'read_all_employees',
          label: 'View Store Fitters',
          requirements: ['read_all_stores'],
          children: [
            {
              id: 'create_all_employees',
              label: 'Create Store Fitters',
              requirements: ['read_all_stores', 'read_all_employees']
            },
            {
              id: 'update_all_employees',
              label: 'Update Store Fitters',
              requirements: ['read_all_stores', 'read_all_employees']
            },
            {
              id: 'delete_all_employees',
              label: 'Delete Store Fitters',
              requirements: ['read_all_stores', 'read_all_employees']
            },
          ]
        },
        {
          id: 'read_all_interactions',
          label: 'View Store Interactions',
          requirements: ['read_all_stores'],
          children: [
            {
              id: 'create_all_interactions',
              label: 'Create Store Interactions',
              requirements: ['read_all_stores', 'read_all_interactions']
            },
            {
              id: 'update_all_interactions',
              label: 'Update Store Interactions',
              requirements: ['read_all_stores', 'read_all_interactions']
            },
            {
              id: 'delete_all_interactions',
              label: 'Delete Store Interactions',
              requirements: ['read_all_stores', 'read_all_interactions']
            },
          ]
        },
        {
          id: 'read_store_users',
          label: 'View Store Users',
          requirements: ['read_all_stores'],
          children: [
            {
              id: 'create_store_users',
              label: 'Create Store Users',
              requirements: ['read_all_stores', 'read_store_users']
            },
            {
              id: 'update_store_users',
              label: 'Update Store Users',
              requirements: ['read_all_stores', 'read_store_users']
            },
            {
              id: 'delete_store_users',
              label: 'Delete Store Users',
              requirements: ['read_all_stores', 'read_store_users']
            },
            {
              id: 'create_store_user_roles',
              label: 'Create Store User Roles',
              requirements: ['read_all_stores', 'read_store_users']
            },
            {
              id: 'update_store_user_roles',
              label: 'Update Store User Roles',
              requirements: ['read_all_stores', 'read_store_users']
            },
            {
              id: 'delete_store_user_roles',
              label: 'Delete Store User Roles',
              requirements: ['read_all_stores', 'read_store_users']
            },
          ]
        },
        {
          id: 'read_all_shoes_selections',
          label: 'View Store Shoe Selections',
          requirements: ['read_all_stores'],
          children: [
            {
              id: 'update_all_shoes_selections',
              label: 'Update Store Shoe Selections',
              requirements: ['read_all_stores', 'read_all_shoes_selections']
            },
          ]
        },
      ],
    },
    {
      id: 'read_fitted_users',
      label: 'View Fitted Users',
      children: [
        {
          id: 'create_fitted_users',
          label: 'Create Fitted Users',
          requirements: ['read_fitted_users']
        },
        {
          id: 'update_fitted_users',
          label: 'Update Fitted Users',
          requirements: ['read_fitted_users']
        },
        {
          id: 'delete_fitted_users',
          label: 'Delete Fitted Users',
          requirements: ['read_fitted_users']
        },
      ]
    },
    {
      id: 'read_common_shoes',
      label: 'View Common Shoes',
      children: [
        {
          id: 'create_common_shoes',
          label: 'Create Common Shoes',
          requirements: ['read_common_shoes']
        },
        {
          id: 'update_common_shoes',
          label: 'Update Common Shoes',
          requirements: ['read_common_shoes']
        },
        {
          id: 'delete_common_shoes',
          label: 'Delete Common Shoes',
          requirements: ['read_common_shoes']
        },
      ]
    },
    {
      id: 'read_all_surveys',
      label: 'View Common Surveys',
      children: [
        {
          id: 'create_all_surveys',
          label: 'Create Common Surveys',
          requirements: ['read_all_surveys']
        },
        {
          id: 'update_all_surveys',
          label: 'Update Common Surveys',
          requirements: ['read_all_surveys']
        },
        {
          id: 'delete_all_surveys',
          label: 'Delete Common Surveys',
          requirements: ['read_all_surveys']
        },
      ]
    },
  ];


  static store_user_capabilities = [
    {
      id: 'read_own_reports',
      label: 'View Dashboard'
    },
    {
      id: 'read_own_store_profiles',
      label: 'View Store Profile',
      requirements: [],
      children: [
        {
          id: 'update_own_store_profiles',
          label: 'Update Store Profile',
          requirements: ['read_own_store_profiles']
        },
      ]
    },
    {
      id: 'read_own_locations',
      label: 'View Store Locations',
      requirements: [],
      children: [
        {
          id: 'create_own_locations',
          label: 'Create Store Locations',
          requirements: ['read_own_locations']
        },
        {
          id: 'update_own_locations',
          label: 'Update Store Locations',
          requirements: ['read_own_locations']
        },
        {
          id: 'delete_own_locations',
          label: 'Delete Store Locations',
          requirements: ['read_own_locations']
        },
      ]
    },
    {
      id: 'read_own_employees',
      label: 'View Store Fitters',
      requirements: [],
      children: [
        {
          id: 'create_own_employees',
          label: 'Create Store Fitters',
          requirements: ['read_own_employees']
        },
        {
          id: 'update_own_employees',
          label: 'Update Store Fitters',
          requirements: ['read_own_employees']
        },
        {
          id: 'delete_own_employees',
          label: 'Delete Store Fitters',
          requirements: ['read_own_employees']
        },
      ]
    },
    {
      id: 'read_own_interactions',
      label: 'View Store Interactions',
      requirements: [],
      children: [
        {
          id: 'create_own_interactions',
          label: 'Create Store Interactions',
          requirements: ['read_own_interactions']
        },
        {
          id: 'update_own_interactions',
          label: 'Update Store Interactions',
          requirements: ['read_own_interactions']
        },
        {
          id: 'delete_own_interactions',
          label: 'Delete Store Interactions',
          requirements: ['read_own_interactions']
        },
      ]
    },
    {
      id: 'read_store_users',
      label: 'View Store Users',
      requirements: [],
      children: [
        {
          id: 'create_store_users',
          label: 'Create Store Users',
          requirements: ['read_store_users']
        },
        {
          id: 'update_store_users',
          label: 'Update Store Users',
          requirements: ['read_store_users']
        },
        {
          id: 'delete_store_users',
          label: 'Delete Store Users',
          requirements: ['read_store_users']
        },
        {
          id: 'create_store_user_roles',
          label: 'Create Store User Roles',
          requirements: ['read_store_users']
        },
        {
          id: 'update_store_user_roles',
          label: 'Update Store User Roles',
          requirements: ['read_store_users']
        },
        {
          id: 'delete_store_user_roles',
          label: 'Delete Store User Roles',
          requirements: ['read_store_users']
        },
      ]
    },
    {
      id: 'read_own_shoes_selections',
      label: 'View Store Shoe Selections',
      requirements: [],
      children: [
        {
          id: 'update_own_shoes_selections',
          label: 'Update Store Shoe Selections',
          requirements: ['read_own_shoes_selections']
        },
      ]
    },
    {
      id: 'read_own_surveys',
      label: 'View Common Surveys',
      children: [
        {
          id: 'create_own_surveys',
          label: 'Create Common Surveys',
          requirements: ['read_own_surveys']
        },
        {
          id: 'update_own_surveys',
          label: 'Update Common Surveys',
          requirements: ['read_own_surveys']
        },
        {
          id: 'delete_own_surveys',
          label: 'Delete Common Surveys',
          requirements: ['read_own_surveys']
        },
      ]
    },
  ];


  static capabilities = {
    read_all_reports: 'read_all_reports',
    read_own_reports: 'read_own_reports',

    read_all_stores: 'read_all_stores',
    read_own_stores: 'read_own_stores',

    read_all_store_profiles: 'read_all_store_profiles',
    read_own_store_profiles: 'read_own_store_profiles',
    create_all_store_profiles: 'create_all_store_profiles',
    // create_own_store_profiles: 'create_store_profile',
    update_all_store_profiles: 'update_all_store_profiles',
    update_own_store_profiles: 'update_own_store_profiles',

    read_all_locations: 'read_all_locations',
    read_own_locations: 'read_own_locations',
    create_all_locations: 'create_all_locations',
    create_own_locations: 'create_own_locations',
    update_all_locations: 'update_all_locations',
    update_own_locations: 'update_own_locations',
    delete_all_locations: 'delete_all_locations',
    delete_own_locations: 'delete_own_locations',

    read_fitted_users: 'read_fitted_users',
    create_fitted_users: 'create_fitted_users',
    update_fitted_users: 'update_fitted_users',
    delete_fitted_users: 'delete_fitted_users',

    read_store_users: 'read_store_users',
    create_store_users: 'create_store_users',
    update_store_users: 'update_store_users',
    delete_store_users: 'delete_store_users',

    read_store_user_roles: 'read_store_user_roles',
    create_store_user_roles: 'create_store_user_roles',
    update_store_user_roles: 'update_store_user_roles',
    delete_store_user_roles: 'delete_store_user_roles',

    read_own_users: 'read_own_users',
    create_own_users: 'create_own_users',
    update_own_users: 'update_own_users',
    delete_own_users: 'delete_own_users',

    read_all_employees: 'read_all_employees',
    read_own_employees: 'read_own_employees',
    create_all_employees: 'create_all_employees',
    create_own_employees: 'create_own_employees',
    update_all_employees: 'update_all_employees',
    update_own_employees: 'update_own_employees',
    archive_all_employees: 'archive_all_employees',
    archive_own_employees: 'archive_own_employees',
    delete_all_employees: 'delete_all_employees',
    delete_own_employees: 'delete_own_employees',

    read_all_shoes_selections: 'read_all_shoes_selections',
    update_all_shoes_selections: 'update_all_shoes_selections',
    read_own_shoes_selections: 'read_own_shoes_selections',
    update_own_shoes_selections: 'update_own_shoes_selections',

    read_common_shoes: 'read_common_shoes',
    create_common_shoes: 'create_common_shoes',
    update_common_shoes: 'update_common_shoes',

    read_all_surveys: 'read_all_surveys',
    create_all_surveys: 'create_all_surveys',
    update_all_surveys: 'update_all_surveys',
    delete_all_surveys: 'delete_all_surveys',

    read_own_surveys: 'read_own_surveys',
    create_own_surveys: 'create_own_surveys',
    update_own_surveys: 'update_own_surveys',
    delete_own_surveys: 'delete_own_surveys',

    read_all_tags: 'read_all_tags',

    read_all_interactions: 'read_all_interactions',
    read_own_interactions: 'read_own_interactions'
  };

  // static user_has_role(user, role_key) {
  //   return Roles[role_key] &&
  //     user &&
  //     user.claims &&
  //     user.claims.roles &&
  //     user.claims.roles[role_key] === true;
  // }
  //
  // static is_fitted_admin(user) {
  //   return Role.user_has_role(user, Roles.fitted_admin);
  // }
  //
  // static assign_store_role_to_user(uid, store_id, role) {
  //
  // }
  //
  // static remove_store_role_from_user(uid, store_id, role) {
  //
  // }
}
