import numeral from 'numeral';
import firebase from "firebase";
import Model from "./base";
import Joi from '@hapi/joi';
import {ReduxStore} from "./redux";


const initialState = {
  all_stores: [],
  own_stores: {},
  current_store_id: null,
};

export const storeReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_ALL_STORES:
      return {
        ...state,
        all_stores: action.payload.items,
      };

    case actionTypes.GET_OWN_STORE:
      return {
        ...state,
        own_stores: {
          ...state.own_stores,
          [action.payload.id]: action.payload
        },
      };

    case actionTypes.RESET_STORES:
      return initialState;

    case actionTypes.SWITCH_CURRENT_STORE:
      return {
        ...state,
        current_store_id: action.payload.store_id
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_ALL_STORES: 'GET_ALL_STORES_SUCCESS',
  GET_OWN_STORE: 'GET_OWN_STORE',
  RESET_STORES: 'RESET_STORES',
  SWITCH_CURRENT_STORE: 'SWITCH_CURRENT_STORE'
};


export class StoreModel extends Model {

  static columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "street_address",
      label: "Street Address",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "country",
      label: "Country",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "zipcode",
      label: "Zipcode",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "website",
      label: "Website",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "sq_ft",
      label: "Sq ft",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (v) => numeral(v).format('0,0') + ' ft'
      }
    },
    {
      name: "net_sales",
      label: "Net Sales",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (v) => numeral(v).format('$0,0')
      }
    },
    {
      name: "median_income",
      label: "Median Income",
      filterType: 'multiselect',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (v) => numeral(v).format('$0,0')
      }
    },
    {
      name: "tags",
      label: "Tags",
      options: {
        filter: true,
        sort: true,
      }
    },
  ];

  static create_validation = Joi.object({
    name: Joi.string().min(3).max(50).required(),
    email: Joi.string().email({tlds:false}).required(),
    city: Joi.string(),
    state: Joi.string(),
    country: Joi.string(),
    street_address: Joi.string(),
    zipcode: Joi.number(),
    website: Joi.string().uri().allow(""),
    median_income: Joi.number().allow(""),
    sq_ft: Joi.number().allow(""),
    net_sales: Joi.number().allow(""),
    tags: Joi.array().allow(""),
  }).options({ stripUnknown: true, abortEarly: false});

  static edit_validation = Joi.object({
    name: Joi.string().min(3).max(50).required(),
    email: Joi.string().email({tlds:false}).required(),
    city: Joi.string(),
    state: Joi.string(),
    country: Joi.string(),
    street_address: Joi.string(),
    zipcode: Joi.number(),
    website: Joi.string().uri().allow(""),
    median_income: Joi.number().allow(""),
    sq_ft: Joi.number().allow(""),
    net_sales: Joi.number().allow(""),
    tags: Joi.array().allow(""),
  }).options({ stripUnknown: true, abortEarly: false});

  static create_store(store_data) {
    return new Promise((resolve, reject) => {

      const {value, error} = StoreModel.create_validation.validate(store_data);

      if (error)
        return reject(this.formatError(error));

      return firebase
        .firestore()
        .collection('stores')
        .add({
          ...value,
          r: 97,
          g: 98,
          b: 214,
          deleted: false,
          created_at: firebase.firestore.Timestamp.now(),
          updated_at: firebase.firestore.Timestamp.now()
        })
        .then((storeDoc) => {
          console.log('Created store', storeDoc.id, storeDoc);
          resolve({
            id: storeDoc.id
          });
        })
        .catch((error) => {
          console.log('Creating store failed!', error);
          reject(error);
        });
    });
  }

  static get_store_by_id(store_id, callback) {
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .onSnapshot((docSnapshot) => {
        console.log('Received store by id', store_id, docSnapshot.data());
        callback({
          id: docSnapshot.id,
          ...docSnapshot.data()
        });
      });
  }

  static update_store_by_id(store_id, store_data) {

    const {value, error} = StoreModel.edit_validation.validate(store_data);

    if(error)
      return Promise.reject(this.formatError(error));

    return firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .update({
        ...value,
        updated_at: firebase.firestore.Timestamp.now()
      });
  }

  static get_all_stores() {
    firebase
      .firestore()
      .collection('stores')
      // .get()
      // .then((documentSnapshots) => {
      .onSnapshot((documentSnapshots) => {
        const items = documentSnapshots.docs.map((doc) => {
          const docData = doc.data();
          const item_data = {
            id: doc.id
          };
          StoreModel.columns.forEach((column) => {
            item_data[column.name] = docData[column.name] ? docData[column.name] : '';
          });
          return item_data;
        });

        ReduxStore.dispatch({
          type: actionTypes.GET_ALL_STORES,
          payload: { items }
        });
      })
      // .catch((error) => {
      //   ReduxStore.dispatch({
      //     type: actionTypes.GET_STORES_ERROR,
      //     payload: { error }
      //   });
      // });
  }

  static get_own_stores_by_ids(store_ids) {
    // for(const store_id of store_ids) {
    const store_id = store_ids[0];
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .onSnapshot((doc) => {
        const docData = doc.data();
        const own_store = {id: doc.id};
        StoreModel.columns.forEach((column) => {
          own_store[column.name] = docData[column.name] ? docData[column.name] : '';
        });
        ReduxStore.dispatch({
          type: actionTypes.GET_OWN_STORE,
          payload: own_store
        });
      });
    // }
  }

  static _switch_store(target_store_id, stores) {
    if(!stores)
      return;
    const store_ids = Object.keys(stores);
    if(!store_ids.length)
      return;

    let store_id;
    if (!target_store_id) {
      store_id = store_ids[0];
    }
    else {
      store_ids.forEach(id => {
        if (id === target_store_id)
          store_id = {...stores[store_id]};
      });
    }

    ReduxStore.dispatch({
      type: actionTypes.SWITCH_CURRENT_STORE,
      payload: {store_id}
    });
  }

  // static switch_all_store(store_id) {
  //   StoreModel._switch_store(store_id, ReduxStore.getState().stores.all_stores);
  // }

  static switch_own_store(store_id) {
    StoreModel._switch_store(store_id, ReduxStore.getState().stores.own_stores);
  }

  static get_current_store() {
    const stores = ReduxStore.getState().stores;
    if(stores.current_store_id && stores.own_stores[stores.current_store_id])
      return stores.own_stores[stores.current_store_id];
    else
      return {};
  }
}
