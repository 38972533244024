import React from 'react';
import ReactFC from 'react-fusioncharts';


const BrandFitByEmployeeChart = (props) => {
  if (!props.employees_data || !Object.keys(props.employees_data).length)
    return <div>No data to display.</div>;

  const data = props.employees.map(employee => ({
    label: employee,
    value: props.employees_data[employee].interactions
  }));

  return (
    <ReactFC  type='column2d'
      dataFormat='json'
      width='100%'
      height='100%'
      dataSource={{
        data,
          chart: {
          caption: "Fits by Team Member",
            subCaption: "",
            xAxisName: "Team Members",
            yAxisName: "Fits",
            numberSuffix: "",
            plottooltext: "$label: <strong>$dataValue</strong> fits",
            theme: "fusion",
            labelDisplay: "rotate",
            slantLabel: "1"
        }
      }}
      />
  );
};

export default BrandFitByEmployeeChart;

