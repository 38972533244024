import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import moment from 'moment';
import FusionCharts from 'fusioncharts';
import {connect} from "react-redux";
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import {Grid} from '@material-ui/core'
import Loader from '../../../components/loader/loader';
import BrandPullByEmployeeChart from "./brand_pull_by_employee_chart";
import BrandFitByEmployeeChart from "./brand_fit_by_employee_chart";
import ChartTabs from "./chart_tabs";
import BrandPullEfficiencyChart from "./brand_pull_efficiency_chart";
import BrandPullChart from "./brand_pulls_chart";
import BrandSalesPerformanceByAgeBarChart from "./brand_sales_performance_by_age_bar";
import BrandSalesPerformanceByMileageBarChart from "./brand_sales_performance_by_mileage_bar";
import BrandSalesPerformanceByAgePieChart from "./brand_sales_performance_by_age_pie";
import BrandSalesPerformanceByMileagePieChart from "./brand_sales_performance_by_mileage_pie";
import BrandSalesEfficiency from "./brand_sales_efficiency";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import DatePickerDropdown from "./date_picker";
import {faCompress, faCompressAlt} from '@fortawesome/free-solid-svg-icons';
import config from '../../../config';
import FiltersPopover from "./filters/filters_popover";


import './main.scss'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const date_format = 'MM-DD-YYYY h:mm a';


function StoreReport(props) {

  const query = new URLSearchParams(window.location.search);

  const [data, set_data] = useState(null);
  const [filters, set_filters] = useState({});
  const [maximized, set_maximized] = useState(false);
  const [params, set_params] = useState();
  const [headers, set_headers] = useState();
  const [is_loading, set_is_loading] = useState(false);
  const [is_web_app, set_is_web_app] = useState(false);
  const [start_date, set_start_date] = useState(query.has('start_date') && moment(query.get('start_date')).startOf('day').utc());
  const [end_date, set_end_date] = useState(query.has('end_date') && moment(query.get('end_date')).local().endOf('day').utc());

  useEffect(() => {
    // Check if the page accessed from the web app
    if (props.store_id && props.user && props.user.token)
      set_is_web_app(true);
  }, [props.store_id, props.user, props.user.token]);

  const loadChart = useCallback(() => {
    if(!start_date || !end_date)
      return;

    let retailer_id;
    if (is_web_app) {
      retailer_id = props.store_id;
      set_headers({
        'authorization': 'Bearer ' + props.user.token
      });
    }
    else if (query.has('token')) {
      const token = query.get('token');
      // If there is a token in querystring (page accessed from the ios app)
      retailer_id = props.match.params.store_id;
      set_headers({
        'authorization': 'Bearer ' + token
      });
    }

    if (retailer_id) {
      const filter_ids = {};
      if(filters['locations'] && filters['locations'].length)
        filter_ids['locations'] = filters['locations'].map(v => v.id);

      if(filters['employees'] && filters['employees'].length)
        filter_ids['employees'] = filters['employees'].map(v => v.id);

      set_params({
        retailer_id,
        start_date: start_date.format(),
        end_date: end_date.format(),
        ...filter_ids
      });
    }

    set_data(null);

  }, [props.store_id, props.user, props.match, filters, start_date, end_date, query, is_web_app]);

  useEffect(loadChart, [window.location.search, props.store_id, props.user, start_date, end_date, filters]);

  useEffect(() => {
    if (headers && params && params.retailer_id) {
      set_is_loading(true);
      axios
        .get(config.api_base_url + 'report', {params, headers})
        .then(res => {
          set_data(res.data);
          console.log(res.data);
        })
        .catch(e => console.error(e))
        .finally(() => set_is_loading(false));
    }
  }, [params, headers]);

  useEffect(() => {
    console.log('Filters', filters);
  }, [filters]);

  const MaximizeButton = useCallback(() => {
    return (
      <button onClick={() => set_maximized(!maximized)}
              // ref={ref}
              style={{
                position: 'absolute',
                right: 16,
                top: 16,
                flexDirection: 'row',
                flexWrap: 'wrap',
                background: 'none',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
                fontSize: '28px',
                color: '#222',
                zIndex: 999
              }}>
        <FontAwesomeIcon icon={maximized ? faCompressAlt : faCompress}/>
      </button>
    );
  }, [maximized, set_maximized]);

  const lg = maximized ? 12 : 6;
  const md = maximized ? 12 : 6;

  const chartContainerStyle = maximized ? {height: window.innerHeight - 10} : {height: (window.innerHeight - 10) / 2};

  return (
    <>
      <Grid container direction="row" style={{padding: 0}}>
        <Grid item xs={12} md={6}>
          {
            data &&
            <div style={{padding: '20px 5px 10px'}}>
              <strong>{data.store}</strong> {moment.utc(data.start_date).local().format(date_format)} to {moment.utc(data.end_date).local().format(date_format)}
            </div>
          }
        </Grid>
        <Grid item xs={12} md={6} style={{
          textAlign: 'right',
          padding:'0 4px',
          flexDirection: 'row',
          flexWrap: 'wrap',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}>
          {
            is_web_app &&
            <>
              <FiltersPopover store_id={props.store_id}
                              on_filter_change={set_filters} />
              <DatePickerDropdown onStartDateChange={set_start_date}
                                  onEndDateChange={set_end_date} />
            </>
          }
        </Grid>
      </Grid>
      {
        is_loading && <Loader/>
      }
      {
        (!is_loading && data) &&
        <Grid container direction="row" className='chart-layout' style={{padding: 0}}>

          <Grid item xs={12} md={md} lg={lg} className='chart-container' style={chartContainerStyle}>
            <MaximizeButton/>

            <ChartTabs labels={['Pulls', 'Fits']}>
              <BrandPullByEmployeeChart brands={data.brands} employees={data.employees}
                                        employees_data={data.employee_data}/>
              <BrandFitByEmployeeChart brands={data.brands} employees={data.employees}
                                       employees_data={data.employee_data}/>
            </ChartTabs>
            {/*interactions*/}
          </Grid>

          <Grid item xs={12} md={md} lg={lg} className='chart-container' style={chartContainerStyle}>
            <MaximizeButton/>
            <ChartTabs labels={['%', '#']}>
              {/* Total brand pulls (percentage) */}
              <BrandPullEfficiencyChart brands={data.brands}
                                        brands_data={data.brand_pulls}/>

              {/* Total brand pulls (number) */}
              <BrandPullChart brands={data.brands}
                              brands_data={data.brand_pulls}/>
            </ChartTabs>
          </Grid>

          <Grid item xs={12} md={md} lg={lg} className='chart-container' style={chartContainerStyle}>
            <MaximizeButton/>
            {/* Brand Sales Performance - Age - Bar */}
            <BrandSalesPerformanceByAgeBarChart brands={data.brands}
                                                brand_pulls={data.brand_pulls}
                                                ages={data.ages}/>
          </Grid>

          <Grid item xs={12} md={md} lg={lg} className='chart-container' style={chartContainerStyle}>
            <MaximizeButton/>
            {/*/!* Brand Sales Performance - Mileage - Bar *!/*/}
            <BrandSalesPerformanceByMileageBarChart brands={data.brands}
                                                    brands_data={data.brand_pulls}
                                                    running_mileages={data.running_mileages}/>
          </Grid>

          <Grid item xs={12} md={md} lg={lg} className='chart-container'
                style={{height: (window.innerHeight - 10) / 2}}>
            <MaximizeButton/>
            {/* Brand Sales Performance - Age - Pie */}
            <BrandSalesPerformanceByAgePieChart cols={maximized ? 3 : 2}
                                                ages={data.ages}
                                                brands={data.brands}
                                                brands_data={data.brand_pulls}/>
          </Grid>

          <Grid item xs={12} md={md} lg={lg} className='chart-container'
                style={{height: (window.innerHeight - 10) / 2}}>
            <MaximizeButton/>
            {/* Brand Sales Performance - Mileage - Pie */}
            <BrandSalesPerformanceByMileagePieChart cols={maximized ? 3 : 2}
                                                    brands={data.brands}
                                                    brands_data={data.brand_pulls}
                                                    running_mileages={data.running_mileages}/>
          </Grid>

          <Grid item xs={12} md={md} lg={lg} className='chart-container' style={chartContainerStyle}>
            <MaximizeButton/>
            {/* Brand Sales Efficiency % */}
            <BrandSalesEfficiency brands={data.brands}
                                  brands_data={data.brand_pulls}/>
          </Grid>

        </Grid>
      }
    </>
  );
}

StoreReport.propTypes = {
  store_id: PropTypes.string,
};

const mapStateToProps = state => ({
  user: state.auth
});

export default withRouter(connect(mapStateToProps)(StoreReport));
