import firebase from 'firebase';
import {ReduxStore, rootActionTypes} from "./redux";


// Use null to show the auth loading state and {} to show not logged in state
const initialState = null;

export const types = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS'
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGIN:
      return {
        ...state,
        ...action.payload
      };
    case types.USER_LOGOUT:
      return {};
    case types.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};


export default class Auth {

  static login(email, password) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((data) => {
          console.log('Login was successful. User:', data.user);
          Auth.update_last_login(data.user.uid);
          resolve(data.user);
        })
        .catch((error) => {
          console.log('Login failed.', error);
          reject(error);
        });
    });
  }

  static observeUser() {
    // onAuthStateChanged returns a unsubscribe function. We will return this
    // to allow the caller use it to unsubscribe.
    return firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          Auth.get_user_profile(user.uid);

          user
            .getIdTokenResult()
            .then((IdTokenResult) => {
              const payload = {
                ...user,
                ...IdTokenResult
              };
              ReduxStore.dispatch({ type: types.USER_LOGIN, payload});
            });
        }
        else {
          // User is signed out.
          ReduxStore.dispatch({type: rootActionTypes.RESET_APP});
          ReduxStore.dispatch({ type: types.USER_LOGOUT, payload: {} });
        }
      });
  }

  // static createUser(email, password) {
  //   firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .catch((error) => {
  //       // Handle Errors here.
  //       // var errorCode = error.code;
  //       // var errorMessage = error.message;
  //     });
  // }

  static logout() {
    firebase
      .auth()
      .signOut()
      .catch((error) => {
        console.log(error);
      });
  }

  // static get_user_claims() {
  //   return new Promise(async (resolve, reject) => {
  //     firebase
  //       .auth()
  //       .currentUser
  //       .getIdTokenResult()
  //       .then(resolve)
  //       .catch(reject);
  //   });
  // }

  static get_user_profile(user_id) {
    firebase
      .firestore()
      .collection('users')
      .doc(user_id)
      .onSnapshot((userDoc) => {
        ReduxStore.dispatch({ type: types.GET_USER_PROFILE_SUCCESS, payload: userDoc.data()});
      });
  }

  static update_last_login(user_id) {
    return firebase
      .firestore()
      .collection('users')
      .doc(user_id)
      .update({
        last_login: firebase.firestore.Timestamp.now(),
      });
  }

  // static getUsersByRole(role) {
  //   return new Promise((resolve, reject) => {
  //     firebase
  //       .firestore()
  //       .collection('users')
  //       .where('roles', 'array-contains', role)
  //       .get()
  //       .then(resolve)
  //       .catch(reject);
  //   });
  // }
}
