import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {Switch} from 'react-router-dom';
import MasterLayout from "../layouts/master";
import {StoreModel} from "../../models/store";
import {withRouter, NavLink} from "react-router-dom";
import {Tabs, Tab} from '@material-ui/core';
import StoreProfile from '../common/store_profile';
import StoreLocations from "../common/store_locations";
import Role from "../../models/role";
import StoreShoesSelectionPage from "./shoes_selection_page";
import StoreEmployees from "../common/store_employees";
import StoreUsers from "../common/store_users";
import StoreSurveys from "../common/store_surveys";
import config from "../../config";
import PrivateRoute from "../../routes/private";
import StoreSurveyForm from "../common/store_survey_form";

const tab_ids = ['profile', 'shoes', 'locations', 'fitters', 'users', 'surveys', 'survey', 'interactions'];
const get_current_tab_id = (url = '') => {
  const parts = url.split('/');
  while (parts.length) {
    let id = parts.pop();
    if (tab_ids.indexOf(id) >= 0) {
      switch (id) {
        case 'survey':
        case 'surveys':
          return 'surveys';
        default:
          return id;
      }
    }
  }
  return '';
};

const StorePage = (props) => {

  const [tab_id, set_tab_id] = useState(get_current_tab_id(props.location.pathname));
  const [store, set_store] = useState({});

  useEffect(() => {
    if(props.match.params.store_id)
      StoreModel.get_store_by_id(props.match.params.store_id, set_store);
  }, [props.match.params.store_id]);

  useEffect(() => {
    const id = get_current_tab_id(props.location.pathname);
    set_tab_id(id);
  }, [props.location.pathname]);

  if(!props.match.params.store_id)
    return <p>Unknown store!</p>;

  return (
    <MasterLayout app_bar={
      <>
        <h1 style={{fontSize:22,padding:'0 0 0 20px'}}>{store.name}</h1>
        <Tabs
        value={tab_id}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        style={{flex: 1}}
      >
        {
          Role.user_has_capability(Role.capabilities.read_all_store_profiles) &&
          <Tab label="Profile" value='profile' component={NavLink} activeClassName='Mui-selected'
               to={config.routes.fitted.store_profile.replace(':store_id', store.id)} />
        }
        {
          Role.user_has_capability(Role.capabilities.read_all_shoes_selections) &&
          <Tab label="Shoes" value="shoes" component={NavLink} activeClassName='Mui-selected'
               to={config.routes.fitted.store_shoes.replace(':store_id', store.id)} />
        }
        {
          Role.user_has_capability(Role.capabilities.read_all_locations) &&
          <Tab label="Locations" value='locations' component={NavLink} activeClassName='Mui-selected'
               to={config.routes.fitted.store_locations.replace(':store_id', store.id)} />
        }
        {
          Role.user_has_capability(Role.capabilities.read_all_employees) &&
          <Tab label="Fitters" value='fitters' component={NavLink} activeClassName='Mui-selected'
               to={config.routes.fitted.store_fitters.replace(':store_id', store.id)} />
        }
        {
          Role.user_has_capability(Role.capabilities.read_store_users) &&
          <Tab label="Users" value='users' component={NavLink} activeClassName='Mui-selected'
               to={config.routes.fitted.store_users.replace(':store_id', store.id)} />
        }
        {
          Role.user_has_capability(Role.capabilities.read_all_surveys) &&
          <Tab label="Surveys" value='surveys' component={NavLink} activeClassName='Mui-selected'
               to={config.routes.fitted.store_surveys.replace(':store_id', store.id)} />
        }
        {
          Role.user_has_capability(Role.capabilities.read_all_interactions) &&
          <Tab label="Interactions" value='interactions' component={NavLink} activeClassName='Mui-selected'
               to={config.routes.fitted.store_interactions.replace(':store_id', store.id)} />
        }
      </Tabs>
      </>
    }>
      <div>
        <Switch>

          <PrivateRoute
            exact path={config.routes.fitted.store_profile}
            capability={Role.capabilities.read_all_store_profiles}
            component={()=> (
              <StoreProfile store={store}
                            can_edit={Role.user_has_capability(Role.capabilities.update_all_store_profiles)} />
            )} />

          <PrivateRoute
            exact path={config.routes.fitted.store_shoes}
            capability={Role.capabilities.read_all_shoes_selections}
            component={()=> (
              <StoreShoesSelectionPage store_id={store.id} />
            )} />

          <PrivateRoute
            exact path={config.routes.fitted.store_locations}
            capability={Role.capabilities.read_all_locations}
            component={()=> (
              <StoreLocations store_id={store.id}
                              can_create={Role.user_has_capability(Role.capabilities.create_all_locations)}
                              can_update={Role.user_has_capability(Role.capabilities.update_all_locations)}
                              can_delete={Role.user_has_capability(Role.capabilities.delete_all_locations)} />
            )} />

          <PrivateRoute
            exact path={config.routes.fitted.store_fitters}
            capability={Role.capabilities.read_all_employees}
            component={()=> (
              <StoreEmployees store_id={store.id}
                              can_create={Role.user_has_capability(Role.capabilities.create_all_employees)}
                              can_update={Role.user_has_capability(Role.capabilities.update_all_employees)}
                              can_delete={Role.user_has_capability(Role.capabilities.delete_all_employees)} />
            )} />

          <PrivateRoute
            exact path={config.routes.fitted.store_users}
            capability={Role.capabilities.read_store_users}
            component={()=> (
              <StoreUsers store_id={store.id}
                          can_create={Role.user_has_capability(Role.capabilities.create_store_users)}
                          can_update={Role.user_has_capability(Role.capabilities.update_store_users)}
                          can_delete={Role.user_has_capability(Role.capabilities.delete_store_users)} />
            )} />

          <PrivateRoute
            exact path={config.routes.fitted.store_surveys}
            capability={Role.capabilities.read_all_surveys}
            component={()=> (
              <StoreSurveys store_id={store.id}
                            can_create={Role.user_has_capability(Role.capabilities.create_all_surveys)}
                            can_update={Role.user_has_capability(Role.capabilities.update_all_surveys)}
                            can_delete={Role.user_has_capability(Role.capabilities.delete_all_surveys)} />
            )} />

          <PrivateRoute
            exact path={config.routes.fitted.store_survey}
            capability={Role.capabilities.read_all_surveys}
            component={(props) => (
              <StoreSurveyForm store_id={store.id} survey_id={props.match.params.survey_id}
                               can_create={Role.user_has_capability(Role.capabilities.create_all_surveys)}
                               can_update={Role.user_has_capability(Role.capabilities.update_all_surveys)}
                               can_delete={Role.user_has_capability(Role.capabilities.delete_all_surveys)} />
            )} />

          <PrivateRoute
            exact path={config.routes.fitted.store_interactions}
            capability={Role.capabilities.read_all_interactions}
            component={()=> (
              <p>Interactions coming soon!</p>
            )} />

        </Switch>
      </div>
    </MasterLayout>
  );
};

StorePage.propTypes = {

};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
});

export default withRouter(connect(mapStateToProps)(StorePage));
