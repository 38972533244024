import React from 'react';
import ReactFC from 'react-fusioncharts';


const BrandSalesEfficiency = (props) => {
  if (!props.brands_data || !Object.keys(props.brands_data).length)
    return <div>No data to display.</div>;

  // total times X-brand sold divided by total fits in which X-brand was pulled
  const data = props.brands.map(brand => ({
    label: brand,
    value: (props.brands_data[brand].purchased / props.brands_data[brand].interactions) * 100
  }));

  return (
    <ReactFC  type='column2d'
              dataFormat='json'
              width='100%'
              height='100%'
              dataSource={{
                chart: {
                  caption: "Brand Sales Efficiency",
                  subCaption: "",
                  xAxisName: "Brands",
                  yAxisName: "Efficiency %",
                  numberSuffix: "%",
                  theme: "fusion",
                  labelDisplay: "rotate",
                  slantLabel: "1",
                },
                data
              }} />
  );
};

export default BrandSalesEfficiency;
