import React from 'react';
import ReactFC from 'react-fusioncharts';
import BrandSalesPerformanceByAgeBarChart from './brand_sales_performance_by_age_bar';


const BrandSalesPerformanceByMileageBarChart = (props) => {

  if (!props.brands_data || !Object.keys(props.brands_data).length)
    return <div>No data to display.</div>;

  const categories = [{ "category": props.running_mileages.map(mileage => ({"label": mileage})) }];

  const dataset = props.brands.map(brand => ({
    seriesname: brand,
    data: props.running_mileages.map(mileage => ({
      value: props.brands_data[brand].running_mileages[mileage] ? props.brands_data[brand].running_mileages[mileage].purchased : 0
    }))
  }));

  return (
    <ReactFC  type='stackedcolumn2d'
              dataFormat='json'
              width='100%'
              height='100%'
              dataSource={{
                chart: {
                  caption: "Brand Sales Performance - Mileage",
                  subCaption: "",
                  xAxisName: "Mileages",
                  yAxisName: "Total sales",
                  numberSuffix: "",
                  plottooltext: "$seriesName - $percentValue", //"<strong>$dataValue</strong> - <strong>$seriesName</strong>",
                  theme: "fusion",
                  labelDisplay: "rotate",
                  slantLabel: "1",
                  showyaxisvalues: false,
                  // legendposition: "right",
                  // drawcrossline: "1"
                },
                categories,
                dataset
              }} />
  );
};

BrandSalesPerformanceByAgeBarChart.defaultProps = {
  brands: [],
  running_mileages: [],
  data: null
};

export default BrandSalesPerformanceByMileageBarChart;
